/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

 .react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

/*
 * Climind color scale
 */

.react-calendar-heatmap .color-climind-1 {
  fill: #ff0a00;
}
.react-calendar-heatmap .color-climind-2 {
  fill: #f2e933;
}
.react-calendar-heatmap .color-climind-3 {
  fill: #36cc23;
}
// .react-calendar-heatmap .color-climind-4 {
//   fill: #44a340;
// }
// .react-calendar-heatmap .color-climind-5 {
//   fill: #1e6823;
// }

//  .react-calendar-heatmap .color-climind-0 {
//   fill: #eeeeee;
// }
// .react-calendar-heatmap .color-climind-1 {
//   fill: #ff1919;
// }
// .react-calendar-heatmap .color-climind-2 {
//   fill: #ff3232;
// }
// .react-calendar-heatmap .color-climind-3 {
//   fill: #ff4c4c;
// }
// .react-calendar-heatmap .color-climind-4 {
//   fill: #ff7f7f;
// }
// .react-calendar-heatmap .color-climind-5 {
//   fill: #d6e685;
// }
// .react-calendar-heatmap .color-climind-6 {
//   fill: #8cc665;
// }
// .react-calendar-heatmap .color-climind-7 {
//   fill: #44a340;
// }
// .react-calendar-heatmap .color-climind-8 {
//   fill: #1e6823;
// }
// .react-calendar-heatmap .color-climind-9 {
//   fill: #1e6823;
// }
// .react-calendar-heatmap .color-climind-10 {
//   fill: #024e07;
// }

// .colorBox {
//   .color {
//     width: '36px';
//     height: '14px';
//     border-radius: '2px';
//     background: 'rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
//   },
//   .swatch {
//     padding: '5px',
//     background: '#fff',
//     borderRadius: '1px',
//     boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
//     display: 'inline-block',
//     cursor: 'pointer',
//   },
//   .popover {
//     position: 'absolute',
//     zIndex: '2',
//   },
//   .cover {
//     position: 'fixed',
//     top: '0px',
//     right: '0px',
//     bottom: '0px',
//     left: '0px',
//   },
// }

$traffic_color: #ffffff;

#traffic {
    width: 100%;
    height: 100%;
    background: $traffic_color; /* Old browsers */
    .box{
         height:100%;
         padding-top: 0px;
         width: 100;
         margin-left:auto;
         margin-right:auto;
     }
  
    .circle {
      // margin-top: 25%;
      // border: 3px solid rgb(70, 74, 79);
      border: 3px solid rgb(70, 74, 79);
      width: 48px;
      height: 48px;
      float: left;
      -webkit-border-radius: 48px;
      -moz-border-radius: 48px;
      border-radius: 48px;
      margin-left:5px;
      margin-right:auto;
      padding-left: auto;
      padding-right: auto;
    }
}

.stepper-custom {
  padding: 5px !important;
  background-color: transparent !important;
}

.import-field{
  border: 1px solid black;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.import-field-right{
  border: 1px solid black;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

#date-picker-inline{
  background-color: white;
}

// .progress-bar-climind-green {
//   background: rgb(27, 201, 67)!important;
// }
// .progress-bar-climind-red {
//   background: rgb(248, 50, 69)!important;
// }
// .progress-bar-climind-yellow {
//   background: rgb(239, 204, 31)!important;
// }

.apexcharts-yaxis-annotations rect{
  width: calc(100% / 2 - 2.3em);
  opacity: 0.6;
}
.apexcharts-xaxis-annotations rect{
  height: calc(100% / 2 - 2em);
  opacity: 0.6;
}