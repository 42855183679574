// Collapsed Sidebar
@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .app-sidebar {

      &--userbox {
        background: transparent !important;

        .userbox-details,
        .btn-userbox {
          display: none;
        }
        .btn-userbox-collapsed {
          display: block;
          transform: translateY(30px);
        }
        .card-tr-actions {
          visibility: hidden;
          opacity: 0;
        }
      }

      &:not(.app-sidebar--mini):hover {
        .app-sidebar--userbox {
          background: #F4F2FE !important;
          .userbox-details,
          .card-tr-actions {
            display: block;
            
          }
          .btn-userbox{
            display: flex;
            align-items: center;
          }
          .btn-userbox-collapsed {
            display: none;
          }

          .card-tr-actions {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
