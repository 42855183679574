// Core

@import "core";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


body {

  h2{
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 700; 
    line-height: 27px;
    color: #291c33
  }

  h3 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 700; 
    line-height: 22px;
    color: #291c33
  }

  h4 {
    font-Family: "Inter";
    font-Size: 18px;
    line-Height: 21.78px;
    color: #7f7785;
    font-Weight: 400;
  }

  h5 {
    font-Family: "Inter";
    font-Size: 16px;
    line-Height: 21.78px;;
    font-Weight: 700;
    color: #291C33;
  }

  h6 {
    font-Family: "Inter";
    font-Size: 14px;
    line-Height: 19.36px;
    color: #291C33;
    font-Weight: 700;
  }

}




.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


